<template>
  <div class="yt-main">
    <div class="yt-header ">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>数据分析</el-breadcrumb-item>
        <el-breadcrumb-item>人才盘点</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div
      class="yt-container yt-container-flex"
      :class="{
        'is-pack-up': isPackUp
      }"
    >
      <div class="yt-content-tree">
        <template v-if="isPackUp">
          <YTIcon class="pack-up-button" :href="'#icon-zhankai'" @click="isPackUp = false" />
        </template>
        <template v-else>
          <div class="header">
            <YTIcon class="pack-up-button" :href="'#icon-shouqi1'" @click="isPackUp = true" />
            <div>
              <YTIcon style="margin: 0 5px 0 0; font-size: 20px" :href="'#icon-fenlei1'" />
              <p>部门名称</p>
            </div>
          </div>
          <div class="tree has-tool">
            <el-tree
              ref="department-tree"
              :data="departmentData"
              :props="departmentProps"
              node-key="departmentId"
              :highlight-current="true"
              @node-click="handleNodeClick"
            ></el-tree>
          </div>
        </template>
      </div>
      <div class="yt-content">
        <div class="block" style="height: 402px; margin-bottom: 10px">
          <ul class="filter-wrapper">
            <li>
              <el-input v-model="searchForm.name" placeholder="通过姓名搜索" class="yt-search" @keydown.enter.native="reSearchStaff">
                <i slot="suffix" class="el-input__icon el-icon-search" @click="reSearchStaff" />
              </el-input>
            </li>
            <li>
              <el-select v-model="searchForm.performance" placeholder="选择绩效" class="yt-search-select" filterable clearable>
                <el-option v-for="item in performanceList" :key="item.tagId" :label="item.name" :value="item.tagId"> </el-option>
              </el-select>
            </li>
            <li>
              <el-select v-model="searchForm.capability" placeholder="选择潜力" class="yt-search-select" filterable clearable>
                <el-option v-for="item in capabilityList" :key="item.tagId" :label="item.name" :value="item.tagId"> </el-option>
              </el-select>
            </li>
            <li>
              <el-select v-model="searchForm.type" placeholder="选择人才类别" class="yt-search-select" filterable clearable>
                <el-option v-for="item in typeList" :key="item.tagId" :label="item.name" :value="item.tagId"> </el-option>
              </el-select>
            </li>
          </ul>
          <div class="chart-wrapper">
            <div class="item">
              <h4>数据</h4>
              <div id="p-c-chart"></div>
              <div class="p-c-chart-legend">
                <YTIcon :href="'#icon-xingxing'" style="margin-right: 5px"></YTIcon>
                <p style="margin-right: 20px">代表自己</p>
                <YTIcon :href="'#icon-jiantou1'" style="margin-right: 5px"></YTIcon>
                <p>代表他人</p>
              </div>
              <div class="p-c-back"></div>
            </div>
            <div class="item">
              <div style="height: 43px">
                <h4 style="float: left;">人才总比</h4>
                <el-radio-group v-model="dataType" style="float: left; margin-top: 26px; margin-left: 29px">
                  <el-radio :label="0">原始数据</el-radio>
                  <el-radio :label="1">调整后数据</el-radio>
                </el-radio-group>
              </div>
              <div id="ratio-chart" style="width: 100%; height: 311px"></div>
            </div>
          </div>
        </div>
        <div class="block" style="height: 612px;padding: 10px 20px;">
          <el-table
            ref="staffTable"
            :data="staffList"
            v-loading="staffLoading"
            element-loading-text="加载中"
            class="yt-table"
            height="calc(100% - 35px)"
          >
            <el-table-column label="姓名" prop="userName" show-overflow-tooltip />
            <el-table-column label="部门" prop="departmentName" show-overflow-tooltip />
            <el-table-column label="工号" prop="jobId" show-overflow-tooltip />
            <el-table-column label="原始绩效" prop="preAchievement" show-overflow-tooltip />
            <el-table-column label="调整后绩效" prop="achievement" show-overflow-tooltip />
            <el-table-column label="原始潜力" prop="prePotential" show-overflow-tooltip />
            <el-table-column label="调整后潜力" prop="potential" show-overflow-tooltip />
            <el-table-column label="原始总评" prop="preEvaluate" show-overflow-tooltip />
            <el-table-column label="调整后总评" prop="evaluate" show-overflow-tooltip />
            <el-table-column label="操作" width="140px">
              <template slot-scope="scope">
                <span class="tool-button">查看人才画像</span>
                <span class="tool-button">编辑</span>
              </template>
            </el-table-column>
            <div slot="empty" id="empty-slot">
              <template v-if="!staffLoading">
                <YTIcon :href="'#icon-zanwushuju'" style="font-size: 100px"></YTIcon>
              </template>
            </div>
          </el-table>
          <Page
            class="yt-page"
            :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
            :total="total"
            :current="pageNum + 1"
            :page-size-opts="[10, 20, 40, 100]"
            :page-size="pageSize"
            @on-change="changePage"
            @on-page-size-change="changePageSize"
            show-elevator
            show-sizer
            show-total
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import { initChart } from './portrayals/charts/scatterChart'
import departmentApi from '@api/department'
export default {
  name: 'PortrayalList',
  components: { YTIcon },
  data() {
    return {
      isPackUp: false,
      departmentData: [
        {
          name: '所有人员',
          departmentId: 0
        }
      ],
      departmentProps: {
        label: 'name'
      },
      organizationData: {},
      searchForm: {
        name: '',
        performance: '',
        capability: '',
        type: ''
      },
      performanceList: [],
      capabilityList: [],
      typeList: [],
      pcChart: null, //散点图
      performanceEnum: ['低', '中', '高'],
      dataType: 0,
      ratioChart: null, //饼图
      staffList: [],
      staffLoading: false,
      total: 0,
      pageNum: 0,
      pageSize: 10
    }
  },
  mounted() {
    this.getDepartment()
    this.initChart()
  },
  methods: {
    getDepartment() {
      departmentApi.getDepartmentsTree().then(res => {
        this.departmentData = this.departmentData.concat(res.res.children)
        this.organizationData = res.res
        this.setCurrentKey()
      })
    },
    initChart() {
      this.pcChart = this.$echarts.init(document.querySelector('#p-c-chart'))
      this.pcChart.setOption(initChart(this.performanceEnum))
      let option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: 40,
          right: 40,
          icon: 'circle',
          itemGap: 16,
          itemHeight: 6,
          itemWidth: 6,
          orient: 'vertical'
        },
        series: [
          {
            type: 'pie',
            center: [218, '50%'],
            radius: [0, '100px'],
            data: [
              { value: 1048, name: '搜索引擎' },
              { value: 735, name: '直接访问' },
              { value: 580, name: '邮件营销' },
              { value: 484, name: '联盟广告' },
              { value: 300, name: '视频广告' }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      this.ratioChart = this.$echarts.init(document.querySelector('#ratio-chart'))
      this.ratioChart.setOption(option)
      let that = this
      window.onresize = function() {
        that.pcChart.resize()
        that.ratioChart.resize()
      }
    },
    setCurrentKey(departmentId) {
      this.$nextTick(() => {
        this.$refs['department-tree'].setCurrentKey(departmentId ? departmentId : 0)
      })
    },
    handleNodeClick() {
      //树节点点击
    },
    searchStaff() {
      //搜索人员
    },
    reSearchStaff() {
      //重置页码搜索人员
    },
    changePage(pageNum) {
      this.pageNum = pageNum - 1
      // this.searchStaff()
    },
    changePageSize(pageSize) {
      this.pageSize = pageSize
      // this.searchStaff()
    }
  }
}
</script>

<style lang="less" scoped>
.yt-main {
  padding-top: 0;
  .yt-header {
    .flexStyle(flex, flex-start);
  }
  .yt-container {
    height: calc(100% - 48px);
  }
  .yt-content {
    overflow-y: auto;
    box-shadow: none;
    padding: 0 3px 3px;
    background-color: transparent;
  }
}
.block {
  width: 100%;
  background-color: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  .filter-wrapper {
    .flexStyle();
    height: 50px;
    padding: 0 20px;
    border-bottom: 1px solid #e2e4e8;
    li {
      margin-right: 10px;
      &:last-of-type {
        margin-right: 0;
      }
    }
    .yt-search {
      height: 32px;
      ::v-deep .el-input__inner {
        width: 220px;
      }
    }
    .yt-search-select {
      ::v-deep .el-input__inner {
        width: 220px;
        height: 32px;
      }
      ::v-deep .el-input__icon {
        line-height: 32px;
      }
    }
  }
  .chart-wrapper {
    .flexStyle();
    height: calc(100% - 50px);
    .item {
      width: 100%;
      height: 100%;
      position: relative;
      h4 {
        margin-top: 22px;
        margin-left: 20px;
        font-size: @medium;
      }
      &:first-of-type {
        position: relative;
        &:before {
          content: '';
          width: 1px;
          height: 266px;
          background: #e2e4e8;
          position: absolute;
          right: 0;
          top: 60px;
        }
      }
      #p-c-chart {
        width: 100%;
        max-width: 475px;
        height: 311px;
      }
      .p-c-chart-legend {
        .flexStyle();
        position: absolute;
        top: 40px;
        right: 20px;
      }
    }
  }
}
.p-c-back {
  width: 360px;
  height: 216px;
  border: 1px solid red;
  position: absolute;
  top: 76px;
  left: 77px;
}
</style>
