let option = {
  xAxis: {
    name: '绩效',
    nameGap: -20,
    nameTextStyle: {
      color: '#000',
      fontWeight: 'bold',
      verticalAlign: 'top',
      padding: [10, 0, 0, 0]
    },
    axisTick: {
      show: false
    },
    axisLine: {
      symbol: ['none', 'arrow'],
      symbolSize: [9, 11],
      lineStyle: {
        color: '#D9D9D9'
      }
    },
    axisLabel: {
      margin: 10,
      color: '#888888'
    },
    splitLine: {
      show: false
    },
    data: [0, 1, 2]
  },
  yAxis: {
    name: '潜力',
    nameGap: -15,
    nameTextStyle: {
      color: '#000',
      fontWeight: 'bold',
      padding: [0, 50, 0, 0]
    },
    axisTick: {
      show: false
    },
    axisLine: {
      symbol: ['none', 'arrow'],
      symbolSize: [9, 11],
      lineStyle: {
        color: '#D9D9D9'
      }
    },
    axisLabel: {
      margin: 10,
      color: '#888888'
    },
    splitLine: {
      show: false
    },
    data: [0, 1, 2]
  },
  grid: {
    top: '20px',
    left: '66px',
    bottom: '52px',
    right: '4%'
  },
  color: 'red', //三角形颜色
  series: [
    {
      symbol: 'triangle',
      symbolSize: [9, 6],
      data: [
        [10.0, 8.04],
        [8.07, 6.95],
        [13.0, 7.58],
        [9.05, 8.81],
        [11.0, 8.33],
        [14.0, 7.66],
        [13.4, 6.81],
        [10.0, 6.33],
        [14.0, 8.96],
        [12.5, 6.82],
        [9.15, 7.2],
        [11.5, 7.2],
        [3.03, 4.23],
        [12.2, 7.83],
        [2.02, 4.47],
        [1.05, 3.33],
        [4.05, 4.96],
        [6.03, 7.24],
        [12.0, 6.26],
        [12.0, 8.84],
        [7.08, 5.82],
        [5.02, 5.68]
      ],
      type: 'scatter'
    }
  ]
}

export function initChart(performanceEnum) {
  let formatter = (value, index) => {
    return performanceEnum[value]
  }
  option.xAxis.axisLabel.formatter = formatter
  option.yAxis.axisLabel.formatter = formatter
  return option
}
